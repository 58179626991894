var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Co_KWEHknsS8yZFfzkTKs"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { SENTRY_DSN } from './constants'
import { PUBLIC_RONIN_CONFIG } from '@account-service/core/envs'
import { ignoreErrors } from '@account-service/core'

const isProduction = PUBLIC_RONIN_CONFIG === 'production'

if (isProduction)
  Sentry.init({
    autoSessionTracking: false,
    sendClientReports: false,
    denyUrls: [
      /reset-password/i,
      /oauth2\/fallbacks\/apple/i,
      /oauth2\/fallbacks\/facebook/i,
      /oauth2\/fallbacks\/google/i,
    ],
    dsn:
      SENTRY_DSN ||
      'https://bb475829fc724d1283184d9dfc0c5693@o998185.ingest.sentry.io/4504399830712320',
    ignoreErrors,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        blockAllMedia: true,
        maskAllText: true,
        maskAllInputs: true,
      }),
    ],
  })
